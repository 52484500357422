import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import Img from "gatsby-image"
import './news.sass'
import GameListing from '../components/GameListing/GameListing'
import BetSiteListing from '../components/BetSiteListing/BetSiteListing'
import InfoBlocks from '../components/InfoBlocks/InfoBlocks'

class games extends React.Component {
  render () {
    const gameItems = this.props.data.allPrismicGame.edges;
    const pageContent = this.props.data.prismicGamesPage.data
    const betSites = this.props.data.allPrismicBetsite.edges

    const metaTitle = "Best games for esports betting"
    const metaDescription = "See the best esports games for esports betting and find online betsites to bet on esports matches and tournaments"

    return (
      <Layout>
        <div className="games-container">
        <Helmet>
          <title>{`${metaTitle}`}</title>
          <meta name="description" content={metaDescription}/>
          <meta name="image" content={config.siteLogo} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta property="og:url" content={this.props.location.href} />
          <meta property="og:title" content={metaTitle}/>
          <meta property="og:description" content={metaDescription}/>
          <meta property="og:image" content={config.siteLogo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ""}/>
          <meta name="twitter:title" content={metaTitle} />
          <meta name="twitter:description" content={metaDescription} />
          <meta name="twitter:image" content={config.siteLogo} />
        </Helmet>
        <section className="section">
          <div>
              <Img fluid={ pageContent.image.localFile.childImageSharp.fluid }
              style={{position: 'absolute', left: '0', top: '0', width: '100%', height: '100%'}}/>
              <div className="bg-tint-1"></div>
            </div>
            <div className="container">
              <h1 className="title">{pageContent.title.text}</h1>
              <p dangerouslySetInnerHTML={{ __html: pageContent.content.html }} />
            </div>
          </section>
          <section className="section">
            <div className="container">
              <GameListing games={gameItems}/>
            </div>
          </section>
          <section className="section">
            <div className="container">
              <h4 className="subtitle">Bet on esport games</h4>
              <BetSiteListing sites={betSites}/>
            </div>
          </section>
          <section className="section">
            <div className="container">
              <InfoBlocks infoItems={pageContent.info_blocks}/>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default games;

export const pageQuery = graphql`
  query GamesQuery {
    allPrismicGame {
        edges {
          node {
            uid
            data {
              name {
                text
              }
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              logo {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
      prismicGamesPage {
        data {
          title {
            text
          }
          content {
            html
          }
          image {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          info_blocks {
            info_block_title {
              text
            }
            info_block_content {
              html
            }
            info_block_image {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      allPrismicBetsite(limit: 6) {
        edges {
          node {
            uid
            data {
              name {
                text
              }
              website {
                url
              }
              terms_and_conditions_link {
                url
              }
              bonus
              brand_color
              logo {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`;
