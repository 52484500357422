import React, {Component} from "react";
import { Link } from "gatsby";
import Img from "gatsby-image"
import "./GameListing.sass"

class GameListing extends Component {
  render() {
    const games = this.props.games;
    return (
                <div className="columns is-1 is-multiline">
                    {
                    games.map((item, index) => (
                        <div key={index} className="column is-one-fifth">
                        <Link to={"/games/" + item.node.uid}>
                            <div className="box site-box game-card">
                                <Img fluid={ item.node.data.image.localFile.childImageSharp.fluid } alt={ item.node.uid }
                                style={{position: 'absolute', left: '0', top: '0', width: '100%', height: '100%'}}/>
                                <div className="bg-tint-3"></div>
                                        <span className="game-card-title">
                                            {item.node.data.name.text}
                                        </span>
                                        {/* <div className="game-bet-button">
                                            Bet on now
                                        </div> */}
{/*
                                        <Link to={"/" + item.node.uid + "/bets"}>
                                            <button className="button
                                            is-secondary
                                            is-small
                                            is-rounded
                                            is-link
                                            game-bet-button">{item.node.data.name.text} odds</button>
                                        </Link> */}

                            </div>
                        </Link>
                        </div>
                    ))}
                </div>
    );
  }
}

export default GameListing;
